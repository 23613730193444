<template>
  <div>
    <p class="mb-6">FLUJO DE CAJA GENRAL</p>
    <v-row class="my-2">
      <v-col class="4">
        <dos-fechas :fechas="[filtros.desde, filtros.hasta]" @getFechas="getFechas" :icon="false"></dos-fechas>
      </v-col>
      <v-col>
        <select-grupo-negocio
          :SelectDefault="-1"
          :todos="true"
          :dense="true"
          :outlined="true"
          @getSelect="getSelectGrupoNegocio"
        ></select-grupo-negocio>
      </v-col>
      <v-col cols="auto" class="text-right">
        <v-btn class="primary" @click="cargarDatos()" :disabled="cargando" :loading="cargando">Buscar</v-btn>
      </v-col>
    </v-row>
    <v-card v-if="datos">
      <v-card-title> Ingresos</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-row v-for="(item, index) in datos.ingresos.detalles" :key="index">
              <v-col class="ml-4"> {{ item.descripcion }} </v-col>
              <v-col class="text-right">$ {{ funciones.formatoNumeric(item.valor) }} </v-col>
            </v-row>
            <v-divider class="my-3"></v-divider>
            <v-row>
              <v-col class="ml-4"> <strong>TOTAL INGRESOS</strong> </v-col>
              <v-col class="text-right">
                <h3>
                  <strong> $ {{ funciones.formatoNumeric(datos.ingresos.total) }}</strong>
                </h3>
              </v-col>
            </v-row>
          </v-col>

          <v-divider vertical v-show="$vuetify.breakpoint.mdAndUp"></v-divider>
          <v-col cols="4" v-show="$vuetify.breakpoint.mdAndUp">
            <apexchart
              ref="grafricaRefIngresos"
              type="pie"
              height="200"
              :options="chartOptionsIngresos"
              :series="seriesIngresos"
            ></apexchart>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-title>Otros Ingresos</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-row v-for="(item, index) in datos.otros_ingresos.detalles" :key="index">
              <v-col class="ml-4"> {{ item.descripcion }} </v-col>
              <v-col class="text-right">$ {{ funciones.formatoNumeric(item.valor) }} </v-col>
            </v-row>
            <v-divider class="my-3"></v-divider>
            <v-row>
              <v-col class="ml-4"> <strong>TOTAL OTROS INGRESOS</strong> </v-col>
              <v-col class="text-right">
                <h3>
                  <strong> $ {{ funciones.formatoNumeric(datos.otros_ingresos.total) }}</strong>
                </h3>
              </v-col>
            </v-row>
          </v-col>

          <v-divider vertical v-show="$vuetify.breakpoint.mdAndUp"></v-divider>
          <v-col cols="4" v-show="$vuetify.breakpoint.mdAndUp"> </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-title>Egresos cxc/cortesias</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-row v-for="(item, index) in datos.egresos_cxc.detalles" :key="index">
              <v-col class="ml-4"> {{ item.descripcion }} </v-col>
              <v-col class="text-right">$ {{ funciones.formatoNumeric(item.valor) }} </v-col>
            </v-row>
            <v-divider class="my-3"></v-divider>
            <v-row>
              <v-col class="ml-4"> <strong>TOTAL EGRESOS CXC/CORTESIAS </strong> </v-col>
              <v-col class="text-right">
                <h3>
                  <strong> $ {{ funciones.formatoNumeric(datos.egresos_cxc.total) }}</strong>
                </h3>
              </v-col>
            </v-row>
          </v-col>
          <v-divider vertical v-show="$vuetify.breakpoint.mdAndUp"></v-divider>
          <v-col cols="4" v-show="$vuetify.breakpoint.mdAndUp">
            <apexchart
              ref="grafricaRefEgresosCXC"
              type="pie"
              height="200"
              :options="chartOptionsIngresos"
              :series="seriesIngresos"
            ></apexchart>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-title>Egresos</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-row v-for="(item, index) in datos.egresos.detalles" :key="index">
              <v-col class="ml-4"> {{ item.descripcion }} </v-col>
              <v-col class="text-right">$ {{ funciones.formatoNumeric(item.valor) }} </v-col>
            </v-row>
            <v-divider class="my-3"></v-divider>
            <v-row>
              <v-col class="ml-4"> <strong>TOTAL EGRESOS </strong> </v-col>
              <v-col class="text-right">
                <h3>
                  <strong> $ {{ funciones.formatoNumeric(datos.egresos.total) }}</strong>
                </h3>
              </v-col>
            </v-row>
          </v-col>
          <v-divider vertical v-show="$vuetify.breakpoint.mdAndUp"></v-divider>
          <v-col cols="4" v-show="$vuetify.breakpoint.mdAndUp">
            <apexchart
              ref="grafricaRefEgresos"
              type="pie"
              height="200"
              :options="chartOptionsIngresos"
              :series="seriesIngresos"
            ></apexchart>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-title>Otros Egresos</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-row v-for="(item, index) in datos.otros_egresos.detalles" :key="index">
              <v-col class="ml-4"> {{ item.descripcion }} </v-col>
              <v-col class="text-right">$ {{ funciones.formatoNumeric(item.valor) }} </v-col>
            </v-row>
            <v-divider class="my-3"></v-divider>
            <v-row>
              <v-col class="ml-4"> <strong>TOTAL OTROS EGRESOS </strong> </v-col>
              <v-col class="text-right">
                <h3>
                  <strong> $ {{ funciones.formatoNumeric(datos.otros_egresos.total) }}</strong>
                </h3>
              </v-col>
            </v-row>
          </v-col>
          <v-divider vertical v-show="$vuetify.breakpoint.mdAndUp"></v-divider>
          <v-col cols="4" v-show="$vuetify.breakpoint.mdAndUp"> </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-title>Totales</v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="ml-4"> <strong>SALDO INICIAL </strong> </v-col>
          <v-col class="text-right">
            <h3>
              <strong :class="datos.total_inicial < 0 ? 'error--text' : ''">
                $ {{ funciones.formatoNumeric(datos.total_inicial) }}</strong
              >
            </h3>
          </v-col>
        </v-row>
        <v-divider class="my-3"></v-divider>
        <v-row>
          <v-col class="ml-4"> <strong>SALDO ACTUAL </strong> </v-col>
          <v-col class="text-right">
            <h3>
              <strong :class="datos.total_actual < 0 ? 'error--text' : ''">
                $ {{ funciones.formatoNumeric(datos.total_actual) }}</strong
              >
            </h3>
          </v-col>
        </v-row>
        <v-divider class="my-3"></v-divider>
        <v-row>
          <v-col class="ml-4"> <strong>SALDO FINAL </strong> </v-col>
          <v-col class="text-right">
            <h3>
              <strong :class="datos.total_final < 0 ? 'error--text' : ''">
                $ {{ funciones.formatoNumeric(datos.total_final) }}</strong
              >
            </h3>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-text>
        <p class="text-center">No existe resultado</p>
      </v-card-text>
    </v-card>
    <div class="text-center mt-2" v-if="datos">
      <v-btn @click="generarImg()" class="error">PDF</v-btn>
      <v-btn @click="exportarDataExcel()" class="success">EXCEL</v-btn>
    </div>
    <div v-if="mostrarGrafica" id="charts" ref="charts"></div>
  </div>
</template>
  
  <script>
import funciones from '@/funciones/funciones'
import jsPDF from 'jspdf'

import DosFechas from '@/components/DosFechas.vue'
import SelectGrupoNegocio from '@/components/SelectGrupoNegocio.vue'
import ContabilidadService from '@/api/servicios/ContabilidadService'
import moment from 'moment'
import store from '@/store'
import { onBeforeMount, ref } from '@vue/composition-api'
import { read, utils, writeFile } from 'sheetjs-style'
export default {
  components: { DosFechas, SelectGrupoNegocio },

  setup(props) {
    const grafricaRefIngresos = ref(null)
    const grafricaRefEgresosCXC = ref(null)
    const grafricaRefEgresos = ref(null)

    const seriesIngresos = ref([44])
    const chartOptionsIngresos = ref({
      chart: {
        width: '100%',
        type: 'pie',
      },
      labels: [''],
      theme: {
        monochrome: {
          enabled: false,
        },
      },

      plotOptions: {
        pie: {
          dataLabels: {
            offset: -5,
          },
        },
      },

      legend: {
        show: false,
      },
    })

    const filtros = ref({
      desde: moment().format('YYYY-MM-DD'),
      hasta: moment().format('YYYY-MM-DD'),
      grupo_negocio: {
        id: -1,
        descripcion: 'todos',
      },
      id_grupo_negocio: -1,
    })
    const getFechas = fecha => {
      console.log(fecha)
      filtros.value.desde = fecha.desde
      filtros.value.hasta = fecha.hasta
    }
    const datos = ref(null)
    const getSelectGrupoNegocio = dat => {
      filtros.value.grupo_negocio = dat
      filtros.value.id_grupo_negocio = dat.id
    }
    const cargando = ref(false)
    function delay(time) {
      return new Promise(resolve => setTimeout(resolve, time))
    }
    let IngresosLabel = []
    let IngresosValores = []
    let EgresosCxcLabel = []
    let EgresosCxcValores = []
    let EgresosLabel = []
    let EgresosValores = []
    const cargarDatos = () => {
      IngresosLabel = []
      IngresosValores = []
      EgresosCxcLabel = []
      EgresosCxcValores = []
      EgresosLabel = []
      EgresosValores = []
      cargando.value = true
      datos.value = null
      console.log(filtros.value)

      try {
        ContabilidadService.reporteFlujoCajaGeneral(filtros.value)
          .then(async response => {
            if (response.data.estatus) {
              datos.value = {
                ...response.data.datos,
                grupo_negocio: JSON.parse(JSON.stringify(filtros.value.grupo_negocio)),
                desde: filtros.value.desde,
                hasta: filtros.value.hasta,
              }
              await delay(1000)

              datos.value.ingresos.detalles.forEach(element => {
                IngresosLabel.push(element.descripcion)
                IngresosValores.push(element.valor)
              })
              datos.value.egresos_cxc.detalles.forEach(element => {
                EgresosCxcLabel.push(element.descripcion)
                EgresosCxcValores.push(element.valor)
              })
              datos.value.egresos.detalles.forEach(element => {
                EgresosLabel.push(element.descripcion)
                EgresosValores.push(element.valor)
              })

              grafricaRefIngresos.value.updateOptions({
                labels: IngresosLabel,
              })
              grafricaRefIngresos.value.updateSeries(IngresosValores)

              grafricaRefEgresosCXC.value.updateOptions({
                labels: EgresosCxcLabel,
              })
              grafricaRefEgresosCXC.value.updateSeries(EgresosCxcValores)

              grafricaRefEgresos.value.updateOptions({
                labels: EgresosLabel,
              })
              grafricaRefEgresos.value.updateSeries(EgresosValores)
            } else {
              store.commit('setAlert', {
                message: response.data.mensaje,
                type: 'warning',
              })
            }
          })
          .catch(error => {
            store.commit('setAlert', {
              message: 'Error al consultar 100 ' + error,
              type: 'error',
              error: {
                        ...error, 
                        response: error?.response?.data
                    },
                funcion: 'cargarDatos',
            })
          })
          .finally(() => {
            cargando.value = false
          })
      } catch (error) {
        cargando.value = false
        console.error(error)
        store.commit('setAlert', {
          message: 'Error al consultar 200 ' + error,
          type: 'error',
              error: error,
                funcion: 'cargarDatos',
        })
      }
    }
    const mostrarGrafica = ref(false)
    const charts = ref(null)
    const generarImg = async () => {
      var options = {
        series: [
          {
            name: 'Desktops',
            data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
          },
        ],
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        title: {
          text: 'Product Trends by Month',
          align: 'left',
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        },
      }
      mostrarGrafica.value = true
      await delay(100)
      charts.value = null
      var chartw = new ApexCharts(document.querySelector('#charts'), {
        ...seriesIngresos.value,
        ...chartOptionsIngresos.value,
        labels: IngresosLabel,
        series: IngresosValores,
        legend: {
          show: true,
          position: 'bottom',
        },
        chart: {
          width: '100%',
          type: 'pie',
          animations: {
            enabled: false,
          },
        },
      })
      chartw.render()
      console.log(chartw)
      const imgs = []
      await delay(100)
      await chartw.dataURI().then(({ imgURI, blob }) => {
        imgs.push(imgURI)
        mostrarGrafica.value = false
      })

      mostrarGrafica.value = true
      await delay(100)
      charts.value = null
      chartw = new ApexCharts(document.querySelector('#charts'), {
        ...seriesIngresos.value,
        ...chartOptionsIngresos.value,
        labels: EgresosCxcLabel,
        series: EgresosCxcValores,
        legend: {
          show: true,
          position: 'bottom',
        },
        chart: {
          width: '100%',
          type: 'pie',
          animations: {
            enabled: false,
          },
        },
      })
      chartw.render()
      console.log(chartw)
      await delay(100)
      await chartw.dataURI().then(({ imgURI, blob }) => {
        imgs.push(imgURI)
        mostrarGrafica.value = false
      })

      mostrarGrafica.value = true
      await delay(100)
      charts.value = null
      chartw = new ApexCharts(document.querySelector('#charts'), {
        ...seriesIngresos.value,
        ...chartOptionsIngresos.value,
        labels: EgresosLabel,
        series: EgresosValores,
        legend: {
          show: true,
          position: 'bottom',
        },
        chart: {
          width: '100%',
          type: 'pie',
          animations: {
            enabled: false,
          },
        },
      })
      chartw.render()
      console.log(chartw)
      await delay(100)
      await chartw.dataURI().then(({ imgURI, blob }) => {
        imgs.push(imgURI)
        mostrarGrafica.value = false
      })
      console.log(imgs)

      let y = 100
      const pdf = new jsPDF('p', 'px')
      imgs.forEach(imgURI => {
        pdf.addImage(imgURI, 'PNG', 250, y, 150 * 1.3, 150)
        y = y + 150 + 2
      })

      y = 60

      const sumLinea = ( valor) => {
        y +=valor

        return y
      }
      let y2 = 20
      const sumLinea2 = ( valor) => {
        y2 +=valor

        return y2
      }
      const proporcionLogo = (config.VUE_APP_PROPORCION_LOGO || process.env.VUE_APP_PROPORCION_LOGO).split(',')
      pdf.addImage(
          store.state.configuracionGeneral.logo_empresa_2,
          'JPEG',
          20,
          sumLinea2(0),
          proporcionLogo[1] * 40,
          proporcionLogo[0] * 40,
        )
        pdf.setFontSize(10)
        pdf.text(store.state.configuracionGeneral.nombre_empresa, 20, sumLinea2(0) + (proporcionLogo[0] * 40 + 12))
        pdf.setFontSize(10)
        pdf.setFont('Helvetica', 'normal')
        pdf.text(store.state.configuracionGeneral.rif_empresa, 20, sumLinea2(0) + proporcionLogo[0] * 40 + 12 + 12)
        
   
         

        
     

      pdf.setFontSize(10)
      pdf.text(store.state.configuracionGeneral.nombre_empresa + '-' + datos.value.grupo_negocio.descripcion, 400, sumLinea2(20), 'right') 
      pdf.text('FLUJO DE CAJA GENERAL', 400, sumLinea2(10), 'right') 
      pdf.text('Desde ' + datos.value.desde + ' Hasta ' + datos.value.hasta, 400, sumLinea2(10), 'right')


      pdf.setFont('Helvetica', 'bold')
      pdf.setFontSize(14)
      pdf.text('Ingresos', 20, sumLinea(50), 'left') 
      
      pdf.setFont('Helvetica', 'normal')
      pdf.setFontSize(10)

      datos.value?.ingresos.detalles.forEach((item, index) => {
         
        pdf.text(item.descripcion, 40, sumLinea(10), 'left')
        pdf.text('$'+ funciones.formatoNumeric(item.valor) , 250, sumLinea(0), 'right')
      })
      
      pdf.setFont('Helvetica', 'bold')
      pdf.text('TOTAL INGRESOS', 40, sumLinea(10), 'left')
      pdf.text('$'+ funciones.formatoNumeric(datos.value?.ingresos.total) , 250, sumLinea(0), 'right')
      

      pdf.setFontSize(14)
      pdf.text('Otros Ingresos', 20, sumLinea(30), 'left') 
      
      pdf.setFont('Helvetica', 'normal')
      pdf.setFontSize(10) 
      datos.value?.otros_ingresos.detalles.forEach((item, index) => {
         
        pdf.text(item.descripcion, 40, sumLinea(10), 'left')
        pdf.text('$'+ funciones.formatoNumeric(item.valor) , 250, sumLinea(0), 'right')
      })
      
      pdf.setFont('Helvetica', 'bold')
      pdf.text('TOTAL OTROS INGRESOS', 40, sumLinea(10), 'left')
      pdf.text('$'+ funciones.formatoNumeric(datos.value?.otros_ingresos.total) , 250, sumLinea(0), 'right')


      pdf.setFontSize(14)
      pdf.text('Total Ingresos', 20, sumLinea(15), 'left')
      pdf.text('$'+ funciones.formatoNumeric(datos.value?.otros_ingresos.total + datos.value?.ingresos.total) , 250, sumLinea(0), 'right')



      pdf.text('Egresos CXC/Cortesia', 20, sumLinea(30), 'left') 
      
      pdf.setFont('Helvetica', 'normal')
      pdf.setFontSize(10) 
      datos.value?.egresos_cxc.detalles.forEach((item, index) => {
         
        pdf.text(item.descripcion, 40, sumLinea(10), 'left')
        pdf.text('$'+ funciones.formatoNumeric(item.valor) , 250, sumLinea(0), 'right')
      })
      
      pdf.setFont('Helvetica', 'bold')
      pdf.text('Total Egresos CXC/Cortesia', 40, sumLinea(10), 'left')
      pdf.text('$'+ funciones.formatoNumeric(datos.value?.egresos_cxc.total) , 250, sumLinea(0), 'right')
     
       pdf.setFontSize(14)
      pdf.text('Egresos', 20, sumLinea(30), 'left') 
      
      pdf.setFont('Helvetica', 'normal')
      pdf.setFontSize(10) 
      datos.value?.egresos.detalles.forEach((item, index) => {
         
        pdf.text(item.descripcion, 40, sumLinea(10), 'left')
        pdf.text('$'+ funciones.formatoNumeric(item.valor) , 250, sumLinea(0), 'right')
      })
      
      pdf.setFont('Helvetica', 'bold')
      pdf.text('Total Egresos', 40, sumLinea(10), 'left')
      pdf.text('$'+ funciones.formatoNumeric(datos.value?.egresos.total) , 250, sumLinea(0), 'right')
    
      pdf.setFontSize(14)
      pdf.text('Otros Egresos', 20, sumLinea(30), 'left') 
      
      pdf.setFont('Helvetica', 'normal')
      pdf.setFontSize(10) 
      datos.value?.otros_egresos.detalles.forEach((item, index) => {
         
        pdf.text(item.descripcion, 40, sumLinea(10), 'left')
        pdf.text('$'+ funciones.formatoNumeric(item.valor) , 250, sumLinea(0), 'right')
      })
      
      pdf.setFont('Helvetica', 'bold')
      pdf.text('Total Otros Egresos', 40, sumLinea(10), 'left')
      pdf.text('$'+ funciones.formatoNumeric(datos.value?.otros_egresos.total) , 250, sumLinea(0), 'right')


      pdf.setFontSize(14)
      pdf.text('Total Egresos', 20, sumLinea(15), 'left')
      pdf.text('$'+ funciones.formatoNumeric(datos.value?.otros_egresos.total + datos.value?.egresos.total + datos.value?.egresos_cxc.total) , 250, sumLinea(0), 'right')



      pdf.text('Saldo Inicial', 20, sumLinea(30), 'left')
      pdf.text('$'+ funciones.formatoNumeric(datos.value.total_inicial) , 250, sumLinea(0), 'right')
      pdf.text('Saldo Actual', 20, sumLinea(15), 'left')
      pdf.text('$'+ funciones.formatoNumeric(datos.value.total_actual) , 250, sumLinea(0), 'right')
      pdf.text('Saldo Final', 20, sumLinea(15), 'left')
      pdf.text('$'+ funciones.formatoNumeric(datos.value.total_final) , 250, sumLinea(0), 'right')


      pdf.save('Flujo de caja ' + 'Desde ' + filtros.value.desde + ' Hasta ' + filtros.value.hasta + '.pdf')
    }
    const exportarDataExcel = _datos => {
      const wb = utils.book_new()
      const format = {
        font: {
          bold: true,
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
        },
      }

      //////CABECERA//////
      let ws_data = [
        [],

        [
          '',

          {
            v: store.state.configuracionGeneral.nombre_empresa + '-' + datos.value.grupo_negocio.descripcion,
            s: {
              font: {
                bold: true,
                sz: 16,
              },
              alignment: {
                horizontal: 'right',
                vertical: 'center',
              },
            },
          },
        ],
        [
          '',

          {
            v: 'FLUJO DE CAJA GENERAL',
            s: {
              font: {
                sz: 14,
              },
              alignment: {
                horizontal: 'right',
                vertical: 'center',
              },
            },
          },
        ],
        [
          '',

          {
            v: 'Desde ' + datos.value.desde + ' Hasta ' + datos.value.hasta,
            s: {
              font: {
                sz: 14,
              },
              alignment: {
                horizontal: 'right',
                vertical: 'center',
              },
            },
          },
        ],
        [
          '',
          {
            v: 'DETALLES',
            s: {
              font: {
                sz: 14,
              },
              alignment: {
                horizontal: 'center',
                vertical: 'center',
              },
              fill: { fgColor: { rgb: 'BDBDBD' } },
            },
          },
          '',
          {
            v: 'VALORES',
            s: {
              font: {
                sz: 14,
              },
              fill: { fgColor: { rgb: 'BDBDBD' } },
              alignment: {
                horizontal: 'center',
                vertical: 'center',
              },
            },
          },
        ],
      ]
      let joinArry = []
      let joinArry2 = []
      let ytemp = 0
      let y = 0
      let ingTotArry = []
      ws_data.push(['', { v: 'Ingresos', s: { font: { sz: 14, bold: true } } }])
      joinArry.push(y + 4)

      datos.value?.ingresos.detalles.forEach((item, index) => {
        ws_data.push(['', '', item.descripcion, { t: 'n', v: Number(item.valor), z: '"$"#,##0.00_)' }])
      })
      y = 7 + datos.value?.ingresos.detalles.length - 1
      ws_data.push([
        '',
        '',
        { v: 'Total Ingresos', s: { font: { sz: 14, bold: true } } },
        { t: 'n', z: '"$"#,##0.00_)', v: 3, f: 'SUM(D7:D' + y + ')', s: { font: { sz: 14, bold: true } } },
      ])
      ingTotArry.push(y + 1)
      joinArry.push(y)
      ytemp = y + 3
      y = y + (datos.value?.otros_ingresos.detalles.length - 1) + 3
      ws_data.push(['', { v: 'Otros Ingresos ', s: { font: { sz: 14, bold: true } } }])

      datos.value.otros_ingresos.detalles.forEach((item, index) => {
        ws_data.push(['', '', item.descripcion, { t: 'n', v: Number(item.valor), z: '"$"#,##0.00_)' }])
      })

      ws_data.push([
        '',
        '',
        { v: 'Total Otros Ingresos', s: { font: { sz: 14, bold: true } } },
        { t: 'n', z: '"$"#,##0.00_)', v: 3, f: `SUM(D${ytemp}:D${y})`, s: { font: { sz: 14, bold: true } } },
      ])
      ingTotArry.push(y + 1)

      let formulaTotal = ''
      ingTotArry.forEach(ite => {
        formulaTotal += '+D' + ite
      })
      joinArry2.push(y)
      ws_data.push([
        '',
        { v: 'Total Ingresos', s: { font: { sz: 14, bold: true }, fill: { fgColor: { rgb: '00C32F' } } } },
        '',
        {
          t: 'n',
          z: '"$"#,##0.00_)',
          v: 3,
          f: formulaTotal,
          s: { font: { sz: 14, bold: true }, fill: { fgColor: { rgb: '00C32F' } } },
        },
      ])

      let egrTotArry = []
      joinArry.push(y + 1)
      ytemp = y + 4
      y = y + (datos.value?.egresos_cxc.detalles.length - 1) + 4
      ws_data.push(['', { v: 'Egresos CXC/Cortesia', s: { font: { sz: 14, bold: true } } }])

      datos.value.egresos_cxc.detalles.forEach((item, index) => {
        ws_data.push(['', '', item.descripcion, { t: 'n', v: Number(item.valor), z: '"$"#,##0.00_)' }])
      })

      ws_data.push([
        '',
        '',
        { v: 'Total Egresos CXC/Cortesia', s: { font: { sz: 14, bold: true } } },
        { t: 'n', z: '"$"#,##0.00_)', v: 3, f: `SUM(D${ytemp}:D${y})`, s: { font: { sz: 14, bold: true } } },
      ])
      egrTotArry.push(y + 1)
      joinArry.push(y)
      ytemp = y + 3
      y = y + (datos.value?.egresos.detalles.length - 1) + 3
      ws_data.push(['', { v: 'Egresos', s: { font: { sz: 14, bold: true } } }])

      datos.value.egresos.detalles.forEach((item, index) => {
        ws_data.push(['', '', item.descripcion, { t: 'n', v: Number(item.valor), z: '"$"#,##0.00_)' }])
      })

      ws_data.push([
        '',
        '',
        { v: 'Total Egresos ', s: { font: { sz: 14, bold: true } } },
        { t: 'n', z: '"$"#,##0.00_)', v: 3, f: `SUM(D${ytemp}:D${y})`, s: { font: { sz: 14, bold: true } } },
      ])
      egrTotArry.push(y + 1)
      joinArry.push(y)
      ytemp = y + 3
      y = y + (datos.value?.otros_egresos.detalles.length - 1) + 3
      ws_data.push(['', { v: 'Otros Egresos', s: { font: { sz: 14, bold: true } } }])

      datos.value.otros_egresos.detalles.forEach((item, index) => {
        ws_data.push(['', '', item.descripcion, { t: 'n', v: Number(item.valor), z: '"$"#,##0.00_)' }])
      })

      ws_data.push([
        '',
        '',
        { v: 'Total Otros Egresos ', s: { font: { sz: 14, bold: true } } },
        { t: 'n', z: '"$"#,##0.00_)', v: 3, f: `SUM(D${ytemp}:D${y})`, s: { font: { sz: 14, bold: true } } },
      ])
      egrTotArry.push(y + 1)

      formulaTotal = ''
      egrTotArry.forEach(ite => {
        formulaTotal += '+D' + ite
      })
      joinArry2.push(y)
      ws_data.push([
        '',
        { v: 'Total Egresos', s: { font: { sz: 14, bold: true }, fill: { fgColor: { rgb: 'FF7575' } } } },
        '',
        {
          t: 'n',
          z: '"$"#,##0.00_)',
          v: 3,
          f: formulaTotal,
          s: { font: { sz: 14, bold: true }, fill: { fgColor: { rgb: 'FF7575' } } },
        },
      ])

      ws_data.push([])
      ws_data.push([
        '',
        { v: 'Saldo Inicial', s: { font: { sz: 14, bold: true }, fill: { fgColor: { rgb: 'BDBDBD' } } } },
        '',
        {
          t: 'n',
          z: '"$"#,##0.00_)',
          v: datos.value.total_inicial,
          s: { font: { sz: 14, bold: true }, fill: { fgColor: { rgb: 'BDBDBD' } } },
        },
      ])
      ws_data.push([
        '',
        { v: 'Saldo Actual', s: { font: { sz: 14, bold: true }, fill: { fgColor: { rgb: 'BDBDBD' } } } },
        '',
        {
          t: 'n',
          z: '"$"#,##0.00_)',
          v: 3,
          v: datos.value.total_actual,
          s: { font: { sz: 14, bold: true }, fill: { fgColor: { rgb: 'BDBDBD' } } },
        },
      ])
      ws_data.push([
        '',
        { v: 'Saldo Final', s: { font: { sz: 14, bold: true }, fill: { fgColor: { rgb: 'BDBDBD' } } } },
        '',
        {
          t: 'n',
          z: '"$"#,##0.00_)',
          v: 3,
          v: datos.value.total_final,
          s: { font: { sz: 14, bold: true }, fill: { fgColor: { rgb: 'BDBDBD' } } },
        },
      ])
      // let ws = utils.aoa_to_sheet(ws_data);
      const ws = utils.aoa_to_sheet(ws_data)

      let merges = [
        { s: { c: 1, r: 1 }, e: { c: 3, r: 1 } },
        { s: { c: 1, r: 2 }, e: { c: 3, r: 2 } },
        { s: { c: 1, r: 3 }, e: { c: 3, r: 3 } },
        { s: { c: 1, r: 4 }, e: { c: 2, r: 4 } },
      ]

      joinArry.forEach(it => {
        merges.push({ s: { c: 1, r: it + 1 }, e: { c: 3, r: it + 1 } })
      })

      let yFinal = 0
      joinArry2.forEach(it => {
        merges.push({ s: { c: 1, r: it + 1 }, e: { c: 2, r: it + 1 } })
        yFinal = it
      })

      merges.push({ s: { c: 1, r: yFinal + 3 }, e: { c: 2, r: yFinal + 3 } })
      merges.push({ s: { c: 1, r: yFinal + 4 }, e: { c: 2, r: yFinal + 4 } })
      merges.push({ s: { c: 1, r: yFinal + 5 }, e: { c: 2, r: yFinal + 5 } })

      ws['!cols'] = [{ wpx: 30 }, { wpx: 30 }, { wpx: 200 }, { wpx: 200 }]
      ws['!merges'] = merges
      utils.book_append_sheet(wb, ws, 'Flujo de caja')

      writeFile(wb, 'Flujo de caja ' + 'Desde ' + filtros.value.desde + ' Hasta ' + filtros.value.hasta + '.xlsx')
    }
    return {
      datos,
      funciones,
      filtros,
      getFechas,
      cargarDatos,
      moment,
      cargando,
      grafricaRefIngresos,
      seriesIngresos,
      chartOptionsIngresos,
      grafricaRefEgresosCXC,
      grafricaRefEgresos,
      generarImg,
      charts,
      mostrarGrafica,
      exportarDataExcel,
      getSelectGrupoNegocio,
    }
  },
}
</script>
  <style scoped>
#charts {
  height: 500px;
  width: 500px;
}
</style>